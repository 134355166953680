<template>
  <div class="cookies-banner align-items-center justify-content-center">
    <div>
      {{ $t('SHARED.COOKIES_BANNER.WEBSITE_USES_COOKIES') }}
      <span class="privacy-policy-btn-cntr">
        <NuxtLink
          qid="cookies-1"
          to="/privacy"
          target="_blank"
          class="privacy-policy-btn"
          >{{ $t('SHARED.COOKIES_BANNER.PRIVACY_POLICY') }}.</NuxtLink
        >
      </span>
    </div>
    <div class="ms-3 ms-sm-4">
      <a
        qid="cookies-2"
        href="javascript:void(0);"
        class="accept-btn"
        @click="acceptCookies()">
        <span class="btn btn-white-bg accept-btn-span">
          {{ $t('SHARED.COOKIES_BANNER.ACCEPT') }}

        </span>
        </a
      >
    </div>
  </div>
</template>

<script setup>
const { calcCookiesBannerState, acceptCookies, getCookiesBannerState } =
  useCookieBanner();
calcCookiesBannerState();

/**
 * Emit the height change event to the parent component
 */
const emit = defineEmits(['heightChange']);
/**
 * Watch for the cookies banner state and emit the height change event to the parent component
 */
watchEffect(() => {
  if (!getCookiesBannerState()) {
    emit('heightChange', 'cookiesBanner', 0);
  }
});

/**
 * Emit the height change event to the parent component based on the window width
 * if the window width is less than 576px, the height of the cookiebanner is 72px
 * if the window width is between 576px and 617px, the height of the cookiebanner is 54px
 * if the window width is greater than 617px, the height of the cookiebanner is 40px
 * @returns void
 */
const handleHeightChange = () => {
  const windowWidth = window.innerWidth;
  if (getCookiesBannerState()) {
    if (windowWidth < 576) {
      emit('heightChange', 'cookiesBanner', 72);
    } else if (windowWidth >= 576 && windowWidth < 617) {
      emit('heightChange', 'cookiesBanner', 54);
    } else {
      emit('heightChange', 'cookiesBanner', 40);
    }
  }
};
/**
 * Add event listener to check the height of the component on window resize
 */
onMounted(() => {
  window.addEventListener('resize', handleHeightChange);
  handleHeightChange(); // Initial check
});

/**
 * Before unmounting the component, remove the event listener
 */
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleHeightChange);
});
</script>

<style lang="scss" scoped>
.app-container {
  height: 100%;
}

.main-app {
  height: 100%;

  &.cookiesBannerVisible {
    padding-top: 72px;

    @media (min-width: 576px) {
      padding-top: 40px;
    }
  }
}

.cookies-banner {
  display: flex;
  flex-shrink: 0;
  background-color: #e6c84b;
  padding: 9px 16px;
  font-size: 12px;
  line-height: 16px;
  color: var(--cup-general-color-5);
  font-weight: 600;
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 72px;

  @include media-breakpoint-up(sm) {
    height: auto;
  }
  .privacy-policy-btn-cntr {
    border-bottom: 1px solid;
  }

  a {
    font-weight: bold;
    color: var(--cup-general-color-5);

    &.privacy-policy-btn {
      font-weight: 800;
      text-decoration: none;
      padding-top: 4px;
      padding-bottom: 5px;
      &:hover {
        text-decoration: none;
      }
    }

    &.accept-btn {
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: -1px;
      margin-bottom: -1px;
      display: inline-block;
        
      .accept-btn-span{
        font-size: 12px;
        padding: 4px 12px;
        letter-spacing: 0.25px;
        line-height: 14px;
        font-weight: 800;
        color: var(--cup-main-color-1);
      }
    }
  }
}
</style>
